<template>
  <div>
    <div class="row mt-2 mx-2">
      <div class="col-12">
        <v-card elevation="1">
          <v-toolbar flat outlined color="#f2f2f2">
            <v-icon size="40" color="blue">mdi-shield-lock-outline</v-icon>
            <v-toolbar-title>
              <span class="blue--text text-h5 font-weight-bold">
                Permissions
              </span>
            </v-toolbar-title>
          </v-toolbar>
          <v-row>
            <v-col>
              <v-expansion-panels focusable>
                <v-expansion-panel
                  v-for="(permissionGroup, index) in permissionGroups"
                  :key="index"
                >
                  <v-expansion-panel-header>
                    {{ permissionGroup.groupDescription }}
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-container fluid>
                      <v-row>
                        <v-col
                          cols="4"
                          sm="4"
                          md="3"
                          v-for="permission in permissionGroup.uaaPermissionSet"
                          :key="permission.uniqueId"
                        >
                          <v-checkbox
                            dense
                            :label="permission.permissionName"
                            :value="permission.uniqueId"
                            :input-value="selected"
                            class="text-sm"
                            @change="
                              getSelectedPermission(
                                $event,
                                permission.uniqueId
                              )
                            "
                          />
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-col>
          </v-row>
          <v-divider />
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              class="white--text"
              color="red"
              large
              @click="goBack()"
              style="text-transform: unset"
            >
              Cancel
            </v-btn>
            <v-btn
              class="white--text"
              color="blue"
              large
              @click="assignPermsToRole"
              style="text-transform: unset"
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </div>
    </div>
  </div>
</template>
<script>

import { mapGetters } from "vuex";

export default {
  
  data: () => ({
    permissions: [],
    selected: [],
    roleUniqueId: "",
  }),
  methods: {
    getSelectedPermission(evt, permission) {
      let idx = this.selected.indexOf(permission);

      if (idx === -1) {
        this.selected.push(permission);
      } else if (idx !== -1) {
        this.selected.splice(idx, 1);
      }
    },
    async assignPermsToRole() {
      var data = {
        roleUniqueId: this.roleUniqueId,
        permissions: this.selected.map((permission) => ({
          permissionUniqueId: permission,
        })),
      };

      await this.$store.dispatch("assignPermissionsToRole", data);
      this.$router.go(-1);
    },
    goBack() {
      this.$router.go(-1);
    },
  },
  computed: {
    ...mapGetters({
      rolesInMyInstitution: "getRoleInMyInstitution",
      roles: "getRoles",
      permissionGroups: "getPermissionGroups",
      rolePermissions: "getRolePermissions",
    }),
  },

  async mounted() {
    this.roleUniqueId = this.$route.params.roleId;

    await this.$store.dispatch("fetchRolePermissions", this.roleUniqueId);
    await this.$store.dispatch("fetchPermissionGroups");
    await this.$store.dispatch("fetchUserPermissions");

    this.rolePermissions.map((permission) =>
      this.selected.push(permission.permissionUniqueId)
    );

  },
};
</script>

<style scoped>
.background {
  background-color: aliceblue;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
}
</style>